<template>
  <div>
    <Menu />

    <div class="fixed-tags" style="background-color: #e00233; width: 40px; border-top-left-radius: 30px; border-bottom-left-radius: 30px;   position: fixed; top: 50%; right: 0; z-index: 1000;">
      <div class="container mt-2 mb-2">
        <div class="row">
          <div class="col-12 text-end">
            <a style="font-size: 20px; color: #fff;" href="https://www.facebook.com/chaseverittluxurywinelands" target="_blank">
              <i class="bi bi-facebook"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.linkedin.com/company/luxury-winelands-estate/about/" target="_blank">
              <i style="color: #fff;" class="bi bi-linkedin"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.instagram.com/chaseverittluxurywinelands/" target="_blank">
              <i style="color: #fff;" class="bi bi-instagram"></i>
            </a><br/>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="container-fluid g-0 pt-3">
        <div class="row g-0">
          <div class="col text-center">
            <div class="developments-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white">
                  <h1 class="display-2"><strong>New Developments</strong></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-light mt-5">
      <div class="container g-5">
        <div class="row pt-5">
          <div class="col-md-12 pb-5 text-center">
            <p><strong>Introducing</strong></p>
            <h1>Drakenzicht</h1>
            <p>Life is beautiful at Drakenzicht Estate. Nestled within the Klein Drakenstein mountains in Paarl, one of the Cape’s most beautiful areas, this luxury “green” estate seamlessly blends beautiful natural scenery with secure family estate living. Eco-friendly and luxurious, Drakenzicht assures residents that they can enjoy safe, modern living in an idyllic location.</p>
          </div> 
          <div class="col-md-4 text-center pb-5">
            <img src="https://legaro.co.za/wp-content/uploads/2024/05/title-slide03b.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4 text-center">
            <img src="https://legaro.co.za/wp-content/uploads/2024/05/title-slide02b.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4 text-center">
            <img src="https://legaro.co.za/wp-content/uploads/2024/05/title-slide01b.jpg" class="img img-fluid">
          </div>   
        </div>
      </div>
      <center><a target="_blank" href="https://legaro.co.za/developments/drakenzicht/" class="btn cta-btn bord-radius my-5">Visit site</a></center>
    </section>

    <section>
      <div class="container g-5 mb-5">
        <div class="row pt-5">
          <div class="col-md-12 pb-5 text-center">
            <p><strong>Introducing</strong></p>
            <h1>Avec la Terre</h1>
            <p>Discover the epitome of luxury living at our exceptional lifestyle estate, where comfort, sustainability, privacy and nature intertwine. Indulge in an unparalleled lifestyle that cherishes ecological balance, while enjoying the finest amenities and breath-taking natural surroundings.</p>
          </div> 
          <div class="col-md-4 text-center pb-5">
            <img src="https://aveclaterre.co.za/images/properties/res-3-front-view.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4 text-center">
            <img src="https://aveclaterre.co.za/images/properties/resi-1-and-2-rear-view.jpg" class="img img-fluid">
          </div>
          <div class="col-md-4 text-center">
            <img src="https://aveclaterre.co.za/images/properties/resi-3-rear-view.jpg" class="img img-fluid">
          </div>   
        </div>
      </div>
      <center><a target="_blank" href="https://aveclaterre.co.za/" class="btn cta-btn bord-radius my-5">Visit site</a></center>
    </section>

    <Footer />
  </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Menu, Footer 
  },
  mounted() {
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {

  }
}
</script>